import Storage from "@/util/Storage";
import {
    GroupChatSendChat,
    SendChat as SendChatData,
    MessageList,
    ApplyFriendsList,
    ApplyFriendsUnreadNum
} from "@/Api/Message";
import {GroupChatSendChatType, SendChatType} from "@/Api/Message/indexType";
import {UpPhotos} from "@/Api/UpPhoto";
import {MessageListStore} from "@/views/Message/MessageList/Store";
import {Toast} from "vant";


// 群发消息
export function SendGroupChat( body:GroupChatSendChatType,heartbeat = false ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    if (!body.type) body.type = 0;
    return Promise.resolve(GroupChatSendChat({ userId:heartbeat && -1 || userId,token },body).then(res=>{
        return res.data
    }))
}

// 单发消息
export function SendChat(body:SendChatType,heartbeat = false) {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    // eslint-disable-next-line
    // @ts-ignore
    if (!body.type) body.type = 0;
    return Promise.resolve(SendChatData({userId:heartbeat && -1 || userId,token},body).then(res=>{
        return res.data
    }))
}

// 请求消息列表
export function UpMessageList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    return Promise.resolve(MessageList({ userId,token },MessageListStore.getMessageListUpData).then(res=>{
        return res.data
    }))
}

// 请求好友申请列表
export function FriendsApplyList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    return Promise.resolve(ApplyFriendsList({ userId,token }).then(res=>{
        return res.data
    }))
}

// 请求好友申请列表 未读数量
export function FriendsApplyListNum() {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    return Promise.resolve(ApplyFriendsUnreadNum({ userId,token }).then(res=>{
        return res.data
    }))
}

// 上传多张图片
export function UpLoadPhoto(list:any[] = []) {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    const fromData = new FormData()
    fromData.append("functionName","other")
    list.forEach(item=>fromData.append("files",item.file, item.file.name))
    return Promise.resolve(UpPhotos({ userId,token },fromData).then(res=>{
        return res.data;
    }))
}

// 发布图片
export function sendPhotos(list:any[] = [],id = "",state = "groupChat") {
    return (
        new Promise((resolve, reject) => {
            Toast.loading({ duration:0,message:"上传中~~~" })
            UpLoadPhoto(list).then(res=>{
                resolve( res )
            })
        }).then((res:any)=>{
            let strPath = ""
            if (res.length) {
                res.forEach((item:any,index:number)=>{
                    if ( index ){
                        strPath += (";" + item)
                    }else{
                        strPath = item
                    }
                })
            }
            if ( state == "groupChat" ){
                // eslint-disable-next-line
                // @ts-ignore
                return SendGroupChat({ groupId:id,content:strPath,type:2 }).then(res=>{
                    Toast.clear()
                    return res
                })
            }else{
                // eslint-disable-next-line
                // @ts-ignore
                return SendChat({ friendId:id,content:strPath,type:2 }).then(res=>{
                    Toast.clear()
                    return res
                })
            }
        })
    )
}
