

export default [
    {
        path:"/chat",
        name:"chat",
        component:()=>import("../../views/Message/Chat/Chat.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/messageList",
        name:"messageList",
        component:()=>import("../../views/Message/MessageList/MessageList.vue"),
        meta:{
            keepAlive: true
        }
    },
]
