import {
    UserType,
    BodyType,
    HomeNoticeType,
    AuthentSucBody,
    AuthentType,
    LoginType,
    SetUserPass,
    GetCodeType,
    GetSchoolType,
    CouponListType,
    BillListType,
 } from "./InterfaceType"
import { AddAddressType } from "../../Type/index"
import Axios from "../Config/basics"

interface HeadType{
    userId:string | number;
    token:string;
}

// 功能开启状态
export function GetModule ( headers : HeadType , body:BodyType ):Promise<any>{
    return (
        Axios({
            url:"/schoolMenu/getList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 退出登录
export function LogOut ( headers : HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/user/logOut",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 请求详情
export function GetOpenId ( openId="" ):Promise<any>{
    return (
        Axios({
            url:"/app/user/getUserByOpenid",
            method:"POST",
            data:{
                openId
            }
        })
    )
}

// 请求详情
export function GetUserData ( headers : HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/user/queryUser",
            method:"POST",
            headers:headers
        })
    )
}

// 编辑用户数据
export function SetUserData( headers : HeadType , body:UserType<string|number>):Promise<any>{
    return (
        Axios({
            url:"/app/user/updateUserInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 注册
export function SetLogin( body:LoginType ):Promise<any>{
    return (
        Axios({
            url:"app/user/register",
            method:"POST",
            headers:{},
            data:body
        })
    )
}

// 重新绑定 手机号 和 openId
export function AgainPhoneOpenId( headers:HeadType,body:LoginType ):Promise<any>{
    return (
        Axios({
            url:"/app/no_user/no_token/user/updateOpenIdByPhone",
            method:"POST",
            headers:{},
            data:body
        })
    )
}

// 修改支付密码
export function SetPassWord( headers:HeadType,body:SetUserPass ):Promise<any>{
    return (
        Axios({
            url:"/app/user/setPayPassWord",
            method:"POST",
            headers,
            data:body
        })
    )
}

//获取手机号 验证码
export function GetPhoneCode( body:GetCodeType ){
    return (
        Axios({
            url:"/app/user/sendSms",
            method:"POST",
            headers:{},
            data:body
        })
    )
}

// 请求公告 未读数量
export function GetNoticeNum( headers:HeadType,body:HomeNoticeType<number|string> ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/cms/unread/total",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求公告
export function GetNotice(headers:HeadType,body:HomeNoticeType<number|string>):Promise<any>{
    return (
        Axios({
            url:"/app/banner/cms/list",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 公告详情
export function GetNoticeSuc( headers:HeadType,body:{ id:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/cms/detail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 轮播图
export function GetBanner( headers:HeadType,body:any = {} ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/bannerList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 学校列表
export function GetSchool( headers:HeadType,body:GetSchoolType ):Promise<any>{
    return (
        Axios({
            url:"/school/getList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 绑定学校
export function BandSchool( headers:HeadType,body:{ school:string } ):Promise<any>{
    return (
        Axios({
            url:"/app/user/bindSchool",
            method:"POST",
            headers,
            data:body
        })
    )
}

//我的地址列表
export function AddressList( headers:HeadType,body:BodyType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailAddress/list4userId",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 设置默认地址
export function SetDefaultAddress( headers:HeadType,body:{ id:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailAddress/updateDefaultMailAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取默认地址
export function GetDefaultAddress(headers:HeadType):Promise<any>{
    return (
        Axios({
            url:"/campus/mailAddress/getDefaultMailAddress",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 删除地址
export function RemoveAddress( headers:HeadType,body:{ id:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailAddress/deleteMailAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 宿舍楼
export function GetSchoolRoom(headers:HeadType):Promise<any>{
    return (
        Axios({
            url:"/app/common/queryBuildingNumber",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 添加地址
export function SetAddAddress(headers:HeadType,body:AddAddressType):Promise<any>{
    return (
        Axios({
            url:"/campus/mailAddress/addMailAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改地址
export function ChangeAddress(headers:HeadType,body:AddAddressType):Promise<any>{
    return (
        Axios({
            url:"/campus/mailAddress/updateMailAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}

// Money
export  function GetMoney( headers:HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/queryAccount",
            method:"POST",
            headers,
            data:{}
        })
    )
}
//我的账单列表
export function GetBillList( headers:HeadType,body:BillListType ):Promise<any>{
    return (
        Axios({
            url:"/app/queryAccountFlow",
            method:"POST",
            headers,
            data:body
        })
    )
}


// 认证列表
export function GetAutionList(headers:HeadType):Promise<any>{
    return (
        Axios({
            url:"/campus/userRealName/getAuthType",
            method:"POST",
            headers,
            data:{}
        })
    )
}
//认证详情
export function GetAuthentSuc( headers:HeadType,body:AuthentSucBody ):Promise<any>{
    return (
        Axios({
            url:"/campus/userRealName/myRealName",
            method:"POST",
            headers,
            data:body
        })
    )
}
// 认证
export function Authent (headers:HeadType,body:AuthentType):Promise<any>{
    return (
        Axios({
            url:"/campus/userRealName/openRealName",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 优惠卷
export function CouponList(headers:HeadType,body:CouponListType):Promise<any>{
    return (
        Axios({
            url:"/campus/userCoupon/myCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}



// 代取

// 请求送达时间
export function GetTimes( headers:HeadType,body:{ isAdmin?:number } ):Promise<any>{
    return (
        Axios({
            url:"/app/common/querySubPrice",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求送件地址
// GetSchoolRoom 不用传 body 数据

// 请求货物大小
export function GetSize( headers:HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/common/goodsSizeList",
            method:"POST",
            headers,
            data:{}
        })
    )
}


// 发送短信
export function SendNot(headers:HeadType,body:{ phone :string|number;msg:string }):Promise<any>{
    return (
        Axios({
            url:"/app/common/sendMessage",
            method:"POST",
            headers,
            data:body
        })
    )
}



