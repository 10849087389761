

export default [
    {
        path:"/issueGroup",
        name:"issueGroup",
        component:()=>import("../../views/Group/IssueGroup/IssueGroup.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/groupHall",
        name:"groupHall",
        component:()=>import("../../views/Group/GroupHall/GroupHall.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/myParticipate",
        name:"myParticipate",
        component:()=>import("../../views/Group/MyParticipate/MyParticipate.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/groupSuc",
        name:"groupSuc",
        component:()=>import("../../views/Group/GroupSuc/GroupSuc.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/joinList",
        name:"joinList",
        component:()=>import("../../views/Group/JoinList/JoinList.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/secondComment",
        name:"secondComment",
        component:()=>import("../../views/Group/SecondComment/SecondComment.vue"),
        meta:{
            keepAlive: true
        }
    },
]
