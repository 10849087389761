// 区分类型
import {
    Basics,
    Express,
    Help,
    Second, ServerStatus,
    Shop,
    SocializeBasics,
    SocializeExpress,
    SocializeHelp,
    SocializeSecond,
    SocializeShop,
    urlState
} from "@/Api/Config/url";
import {parse} from "qs";
import excludeList from "@/Api/Config/exclude";
import Storage from "@/util/Storage";
import {Dialog, Notify} from "vant";
import router from "@/router";

let userInfo: any = {}

// 请求参数处理
export function getIsSocialize(config: any = {}, state = "base") {
    /** 三易社圈 */
    if (urlState == "all") {
        let url = "";
        switch (state) {
            case "base":
                url = SocializeBasics
                break;
            case "exp":
                url = SocializeExpress
                break;
            case "shop":
                url = SocializeShop
                break;
            case "help":
                url = SocializeHelp
                break;
            case "friend":
                url = SocializeSecond
                break;
            case "hand":
                url = SocializeSecond
                break;
        }

        // 判断是否是社区 社圈全局路由 须加载 linkState 且等于 socialize 不区分大小写
        try {
            let urlArr = window.location.href.split("?"), url = urlArr.length > 1 ? urlArr[1] : urlArr[0];
            let reg = /linkState/g;
            if (reg.test(url)) {
                let data = parse(urlArr[1]),
                    keyArr = Object.keys(data),
                    key = keyArr.findIndex(a => a == "linkState");
                if (key >= 0 && (data[keyArr[key]] as string).toLocaleLowerCase() == "socialize") {
                    config.baseURL = url;
                    return config;
                }
            }
        } catch (e) {
            console.info("三易社圈 接口 转换失败")
        }
    }

    /** 三易校园 */
    try {
        // 排除那些借口 不添加
        let key = excludeList.findIndex(a => new RegExp(a).test(config.url))
        if (key >= 0) return config;
        // 默认添加 登录账号 school
        if ( !(userInfo["school"] && typeof userInfo.school == "string" && userInfo.school.length) || userInfo.id != config.headers.userId) {
            userInfo = JSON.parse(Storage.GetData_?.("UserData"))
        }
        let JsonData = typeof config.headers == "string" ? JSON.parse(config.headers as string) : config.headers;
        JsonData.school = userInfo.school || "";
        // config.body = JSON.stringify(JsonData);
    } catch (e) {
        console.info(e + "添加学校参数失败")
    }
    return config
}

// 请求初始化 参数 负载
export function loadAxios(axios: any = {}, state = "base") {
    let url = "";
    switch (state) {
        case "base":
            // eslint-disable-next-line
            // @ts-ignore
            url = (urlState == "sanyixiaoyuan" || urlState == "all") ? Basics : SocializeBasics;
            break;
        case "exp":
            // eslint-disable-next-line
            // @ts-ignore
            url = (urlState == "sanyixiaoyuan" || urlState == "all") ? Express : SocializeExpress;
            break;
        case "shop":
            // eslint-disable-next-line
            // @ts-ignore
            url = (urlState == "sanyixiaoyuan" || urlState == "all") ? Shop : SocializeShop;
            break;
        case "help":
            // eslint-disable-next-line
            // @ts-ignore
            url = (urlState == "sanyixiaoyuan" || urlState == "all") ? Help : SocializeHelp;
            break;
        case "friend":
            // eslint-disable-next-line
            // @ts-ignore
            url = (urlState == "sanyixiaoyuan" || urlState == "all") ? Second : SocializeSecond;
            break;
        case "hand":
            // eslint-disable-next-line
            // @ts-ignore
            url = (urlState == "sanyixiaoyuan" || urlState == "all") ? Second : SocializeSecond;
            break;
    }
    axios.defaults.baseURL = url;
    axios.defaults.headers.post["Content-Type"] = "application/json"
    axios.defaults.headers.post["type"] = "wxpublic"
    axios.defaults.timeout = (10 * 1000)
}

// 请求返回异常 错误 处理
export function errorDispose(response:any = {},state = "base") {
    let code = response.data.message.code
    if ( code === "200" ){
        return response.data
    }else{
        if ( code === "401" ){
            let time = setTimeout(()=>{
                Dialog.confirm({
                    title: '温馨提示',
                    message: '登录过期请重新登录',
                    beforeClose(action, done) {
                        if (action === 'confirm') {
                            let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
                            if ( bool != null && bool[0] == "micromessenger" ){
                                if ( urlState == "sanyixiaoyuan" ){
                                    if (ServerStatus == "test"){
                                        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://test.mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
                                    } else window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
                                }else{
                                    if (ServerStatus == "test"){
                                        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb64f19aa5da773e0&redirect_uri=https://test.mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
                                    } else window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb64f19aa5da773e0&redirect_uri=https://mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
                                }
                            }else{
                                router.replace({
                                    name:"register"
                                })
                            }
                            done();
                        } else {
                            done();
                        }
                    },
                })
                clearTimeout( time )
            },800)
        }else if ( state == "base" && (/(未注册|手机号未注册)/gi.test(response.data.message.msg) || response.data.message.code == "412") ){
            return response.data
        } else{
            try{
                Notify({
                    message: response.data.message.msg,
                    color: 'white',
                    background: "rgba(0,0,0,.6)",
                });
            }catch (e) {
                console.info("提示失败")
            }
            return null // response.data
        }
    }
}
