

export default [
    { // 我的优惠列表
        path:"/couponList",
        name:"couponList",
        component:()=>import("../../views/Coupon/CouponList.vue"),
        meta:{
            keepAlice:true
        }
    },
    { // 选择优惠卷
        path:"/selCoupon",
        name:"SelCoupon",
        component:()=>import("../../views/Coupon/SelCoupon.vue"),
        meta:{
            keepAlive:true
        }
    },
    /** 管理端 优惠卷 管理 */
    { // 选择优惠卷
        path:"/adminCouponList",
        name:"adminCouponList",
        component:()=>import("../../views/Coupon/AdminCouponList/AdminCouponList.vue"),
        meta:{
            keepAlive:true
        }
    },{ // 添加优惠卷
        path:"/adminAddCoupon",
        name:"adminAddCoupon",
        component:()=>import("../../views/Coupon/AdminAddCoupon/AdminAddCoupon.vue"),
        meta:{
            keepAlive:true
        }
    },
]
