
import { SchoolType,UpDataType,BillListType,AddressType,RoomType } from "../../Type/index"
import store from "../index"
import { Module,VuexModule,Mutation,getModule } from "vuex-module-decorators"


export interface UserType<T=string>{
    SchoolList:SchoolType
    MoneyData:UpDataType<T|number>;
    BillList:BillListType[],
    AddressList:AddressType[]
    RoomList:RoomType[];
    SelAddress:AddressType;
    SetSchoolList(data:SchoolType):void;
    SetMoneyData( data:UpDataType<T|number> ):void;
    SetAddressList(data:AddressType[]):void;
    SetRoomList(data:RoomType[]):void;
    SetSelAddress(data:AddressType):void;
}

@Module({
    name:"User",
    store,
    dynamic:true 
})
export default class User extends VuexModule implements UserType{
    SchoolList: SchoolType = []
    MoneyData:UpDataType<string|number> = {}
    BillList:BillListType[] = []
    AddressList:AddressType[] = []
    RoomList:RoomType[] = []
    SelAddress:AddressType = {};
    // 学校列表
    get GetSchoolList():SchoolType{
        return this.SchoolList
    }
    @Mutation
    SetSchoolList(data:SchoolType){
        this.SchoolList = data
    }
    // 用户金额
    get GetMoneyData(){
        return this.MoneyData
    }
    @Mutation
    SetMoneyData( data:UpDataType<string|number> ){
        this.MoneyData = data
    }
    // 账单
    get GetBillList(){
        return this.BillList
    }
    @Mutation
    SetBillList(data:BillListType[]){
        this.BillList = data
    }
    // 地址列表
    get GetAddressList(){
        return this.AddressList
    }
    @Mutation
    SetAddressList(data:AddressType[]){
        this.AddressList = data
    }
    // 送达宿舍楼地址
    get GetRoomList(){
        return this.RoomList
    }
    @Mutation
    SetRoomList(data:RoomType[]){
        this.RoomList = data
    }
    // 选择地址列表
    get GetSelAddress(){
        return this.SelAddress
    }
    @Mutation
    SetSelAddress(data:AddressType){
        this.SelAddress = data
    }
}

export const UserStore = getModule(User)