

import Axios from "axios"
import { Notify,Dialog  } from "vant"
import {errorDispose, getIsSocialize, loadAxios} from "@/Api/Config/propsUtil";

const axios = Axios.create()

loadAxios(axios,"base")

let userInfo:any = {}


// 请求前
axios.interceptors.request.use( config=>{
    return getIsSocialize(config,"base")
},error=>{
    Promise.reject(error)
} )
// 请求后
axios.interceptors.response.use( response=>{
    return errorDispose(response,"base")
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
} )


export default axios;

// import {Basics, ServerStatus, SocializeBasics,urlState} from "./url"
// import router from "@/router"
// eslint-disable-next-line
// @ts-ignore
// axios.defaults.baseURL = (urlState == "sanyixiaoyuan" || urlState == "all") ? Basics : SocializeBasics;
// axios.defaults.headers.post["Content-Type"] = "application/json"
// axios.defaults.headers.post["type"] = "wxpublic"
// axios.defaults.timeout = (10*1000)

// try{
//     /** 三易社圈 */
//     if ( urlState == "all" ){
//         // 判断是否是社区 社圈全局路由 须加载 linkState 且等于 socialize 不区分大小写
//         try{
//             let urlArr = window.location.href.split("?"),url = urlArr.length > 1 ? urlArr[1] : urlArr[0];
//             let reg = /linkState/g;
//             if ( reg.test(url) ){
//                 let data = parse(urlArr[1]),
//                     keyArr = Object.keys(data),
//                     key = keyArr.findIndex(a=>a == "linkState");
//                 if ( key >= 0 && (data[keyArr[key]] as string).toLocaleLowerCase() == "socialize" ){
//                     config.baseURL = SocializeBasics
//                     return config;
//                 }
//             }
//         }catch (e) {
//             console.info("转换失败")
//         }
//     }
//
//     /** 三易校园 */
//         // 排除那些借口 不添加
//     let key = excludeList.findIndex(a=>new RegExp(a).test(config.headers.url))
//     if ( key < 0 ) return config;
//     // 默认添加 登录账号 school
//     if ( !(userInfo["school"] && typeof userInfo.school == "string" && userInfo.school.length) || userInfo.id != config.headers.userId ){
//         userInfo = JSON.parse(Storage.GetData_?.("UserData"))
//     }
//     let JsonData = typeof config.data == "string" ? JSON.parse(config.data as string) : config.data;
//     JsonData.school = userInfo.school || "";
//     config.data = JSON.stringify(JsonData);
// }catch (e) {
//     console.log(e+"添加学校参数失败")
// }



// 返回参数
// let code = response.data.message.code
// if ( code === "200" ){
//     return response.data
// }else{
//     if ( code === "401" ){
//         let time = setTimeout(()=>{
//             Dialog.confirm({
//                 title: '温馨提示',
//                 message: '登录过期请重新登录',
//                 beforeClose(action, done) {
//                     if (action === 'confirm') {
//                         let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
//                         if ( bool != null && bool[0] == "micromessenger" ){
//                             if (ServerStatus == "test"){
//                                 window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://test.mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
//                             } else window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
//                         }else{
//                             router.replace({
//                                 name:"register"
//                             })
//                         }
//                         done();
//                     } else {
//                         done();
//                     }
//                 },
//             })
//             clearTimeout( time )
//         },800)
//     }else if (/(未注册|手机号未注册)/gi.test(response.data.message.msg) || response.data.message.code == "412"){
//         return response.data
//     } else{
//         try{
//             Notify({
//                 message: response.data.message.msg,
//                 color: 'white',
//                 background: "rgba(0,0,0,.6)",
//             });
//         }catch (e) {
//             console.info("提示失败")
//         }
//         return null // response.data
//     }
// }
