

import Axios from "../Config/basics"


interface TokenType{
    data:string
}

export function WxToken( data:TokenType ):any{
    let url = "/app/weixin/getOpenId?code="+data.data+"&state=STATE"
    return Axios.get(url)
}

interface UserType{
    openid:string,
    accessToken:string
}
export function WxUserInfo ( data:UserType ):any{
    let url = `/app/weixin/getWxUserInfo?openid=${ data.openid }&access_token=${ data.accessToken }`
    return Axios.get(url)
}

