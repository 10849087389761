export default [
    {
        path:"/allHand",
        name:"allHand",
        component:()=>import("../../views/Hand/AllHand.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/handSuc",
        name:"handSuc",
        component:()=>import("../../views/Hand/HandSuc.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/handUser",
        name:"handUser",
        component:()=>import("../../views/Hand/HandUser.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/myHand",
        name:"myHand",
        component:()=>import("../../views/Hand/MyHand.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/issueHand",
        name:"issueHand",
        component:()=>import("../../views/Hand/IssueHand.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/handBuyList",
        name:"handBuyList",
        component:()=>import("../../views/Hand/BuyList.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/handTypeList",
        name:"handTypeList",
        component:()=>import("../../views/Hand/TypeList.vue"),
        meta:{
            keepAlive:true
        }
    }
]