
import { IndexStore } from "@/store/modules/Index";

let touchstartFun1:any;
let touchmoveFun1:any;
let touchendFun1:any;

export function overscroll(el:any) {
    // el = obj
    el.style = "transition:.4s;"

    let top = 0, // 滚动条的值
        totalScroll = 0, // 盒子里面 内容的高度
        currentScroll = 0, // 盒子的高度
        pageY = 0,
        time = 0,
        timeNum = 0,
        bool = false,
        bili = 0.3; // 快速滚动比例值
    function touchstartFun(e:any) {
        top = el.scrollTop
        totalScroll = el.scrollHeight
        currentScroll = el.offsetHeight;
        pageY = e.touches[0].pageY
        timeNum = 0
        time = setInterval(()=>{
            timeNum ++;
        })
        if ( IndexStore.getScrollStopBool ){
            // e.stopPropagation()
            // e.preventDefault()
        }
    }
    function touchmoveFun(evt:any) {
        totalScroll = el.scrollHeight
        let moveY = evt.touches[0].pageY - pageY
        if ( moveY <= 0 ){
            bool = true
            el.scrollTop = top + Math.abs(moveY)
        }else{
            bool = false
            el.scrollTop = top - moveY
        }
        if ( IndexStore.getScrollStopBool ){
            // evt.stopPropagation()
            // evt.preventDefault()
        }
    }
    function touchendFun(evt:any){
        let scrollTop = el.scrollTop,
            scrollNum = totalScroll - currentScroll,
            Num = scrollTop;

        if( timeNum <= 40 ){
            if (currentScroll >= totalScroll || scrollTop <= 1) {
                Num = 0;
            }else if ( scrollNum > 0 && scrollTop >= scrollNum ){
                Num = scrollNum
            }else{
                if ( scrollTop <= top ){
                    if ( bool ){
                        Num = scrollTop + (scrollTop *bili)
                    }else{
                        Num = scrollTop - (scrollTop *bili)
                    }
                }else{
                    if ( bool ){
                        Num = scrollTop + (scrollTop *bili)
                    }else{
                        Num = scrollTop - (scrollTop *bili)
                    }
                }
            }
            if (currentScroll >= totalScroll || Num <= 1) {
                Num = 0;
            }else if ( Num > 0 && Num >= scrollNum ){
                Num = scrollNum
            }
            el.scrollTop = Num
        }


        // if (currentScroll >= totalScroll) {
        //     el.scrollTop = 0;
        // }else if ( scrollTop <= 1 ){
        //     el.scrollTop = 0
        // }else if ( scrollNum > 0 && scrollTop >= scrollNum ){
        //     el.scrollTop = scrollNum
        // }
        if ( IndexStore.getScrollStopBool ){
            // evt.stopPropagation()
            // evt.preventDefault()
        }
        clearInterval( time )
    }
    touchstartFun1 = touchstartFun
    touchmoveFun1 = touchmoveFun
    touchendFun1 = touchendFun
    el.addEventListener("touchstart", touchstartFun,{ passive:false });
    el.addEventListener("touchmove",touchmoveFun,{ passive:false } );
    el.addEventListener("touchend",touchendFun,{ passive:false } )

};

/** 微信禁止滚动 */
let stopScrollFun = function (e:any) {
    if ( "preventDefault" in e ){
        e.preventDefault()
    }
}
export function wxStopScroll(){
    document.body.addEventListener("touchmove",stopScrollFun,{ passive:false })
}


/** ios 禁止滚动 */
export function iosStopScroll(){
    let el:any = document.querySelector("#app");
    el.addEventListener('touchmove',function(e:any){
        e.isSCROLL = true;
    })
    document.body.addEventListener('touchmove',function(e:any){
        if(!e.isSCROLL){
            e.preventDefault(); //阻止默认事件(上下滑动)
        }else{
            //需要滑动的区域
            let top = el.scrollTop; //对象最顶端和窗口最顶端之间的距离
            let scrollH = el.scrollHeight; //含滚动内容的元素大小
            let offsetH = el.offsetHeight; //网页可见区域高
            let cScroll = top + offsetH; //当前滚动的距离

            //被滑动到最上方和最下方的时候
            if(top == 0){
                top = 1; //0～1之间的小数会被当成0
            }else if(cScroll === scrollH){
                el.scrollTop = top - 0.1;
            }
        }
    }, {passive: false})
}

export function isStopScroll(fun?:any){
    let phone = navigator.userAgent;
    let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    // if ( wxBool != null && wxBool[0] == "micromessenger" ){
    //     wxStopScroll()
    // }else if(isiOS){
    //     iosStopScroll() || isiOS
    // }
    if ( wxBool != null && wxBool[0] == "micromessenger"  ){
        wxStopScroll()
        if ( isiOS )iosStopScroll();
        if ( !fun ){
            overscroll(document.querySelector("#app"))
        }else{
            overscroll(fun)
        }
    }

    // else if(isAndroid){ }else{ }
    // let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1; //android终端或者uc浏览器
}

export function RemoveIncident(obj:any) {
    obj.removeEventListener("touchstart", touchstartFun1);
    obj.removeEventListener("touchmove",touchmoveFun1 );
    obj.removeEventListener("touchend",touchendFun1 )
    document.body.removeEventListener("touchmove",stopScrollFun,)
}

export function DelScroll(fun?:any) {
    console.log( fun,"禁止" )
    let phone = navigator.userAgent;
    let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    if ( wxBool != null && wxBool[0] == "micromessenger" || isiOS ){
        if ( !fun ){
            RemoveIncident(document.querySelector("#app"))
        }else{
            RemoveIncident(fun)
        }
    }
}


