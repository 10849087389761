

export default [
	// 地址列表
    {
        path:"/addressList",
        name:"addressList",
        component:()=>import("../../views/Address/AddressList.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 添加地址
    {
        path:"/addaddress",
        name:"addaddress",
        component:()=>import("../../views/Address/AddAddress.vue"),
        meta:{
            keepAlive:true
        }
    },
]