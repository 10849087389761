

export default [
	// 编辑资料
    {
        path:"/userInfo",
        name:"userInfo",
        component:()=>import("../../views/Basics/UserInfo.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 编辑头像
    {
        path:"/userPhoto",
        name:"userPhoto",
        component:()=>import("../../views/Basics/UserPhoto.vue"),
        meta:{
            keepAlive: true
        }
    },
	//编辑昵称
    {
        path:"/userSetName",
        name:"userSetName",
        component:()=> import("../../views/Basics/UserSetName.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 个性签名
    {
        path:"/signature",
        name:"signature",
        component:()=> import("../../views/Basics/UserSignature.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 修改密码
    {
        path:"/userSetPass",
        name:"userSetPass",
        component:()=>import("../../views/Basics/SetPass/SetPass.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 补全信息
    {
        path:"/vTMessage",
        name:"vTMessage",
        component:()=>import("../../views/Basics/VTMessage.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 选择学校
    {
        path:"/school",
        name:"school",
        component:()=>import("../../views/Basics/School.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 个人设置
    {
        path:"/set",
        name:"set",
        component:()=>import("../../views/Basics/Set/Set.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 钱包
    {
        path:"/money",
        name:"money",
        component:()=>import("../../views/Basics/Money.vue"),
        meta:{
            keepAlice:true
        }
    },
	// 我的账单
    {
        path:"/bill",
        name:"bill",
        component:()=>import("../../views/Basics/Bill.vue"),
        meta:{
            keepAlice:true
        }
    },
	// 关于我们
    {
        path:"/about",
        name:"about",
        component:()=>import("../../views/Basics/About.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 公告
    {
        path:"/informList",
        name:"informList",
        component:()=>import("../../views/Basics/InformList.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 通知详情
    {
        path:"/informSuc",
        name:"informSuc",
        component:()=>import("../../views/Basics/InformSuc/InformSuc.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 用户协议
	{
        path:"/userAgreement",
        name:"userAgreement",
        component:()=>import("../../views/Basics/UserAgreement/UserAgreement.vue"),
        meta:{
            keepAlive:true
        }
    },
]
