
import { urlState } from "@/Api/Config/url";

const List = (urlState == "sanyishequ") ? [
    {
        path:"/pickUpSite",
        name:"pickUpSite",
        component:()=>import("../../views/SocialCircle/PickUpSite/PickUpSite.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addPickUpSite",
        name:"addPickUpSite",
        component:()=>import("../../views/SocialCircle/AddPickUpSite/AddPickUpSite.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/productBar", // 商品推荐导航栏   特价导航栏      select=selectType：选择商品
        name:"productBar", // barList:热门推荐导航  special: 特价导航栏 select=selectType：选择商品
        component:()=>import("../../views/SocialCircle/ProductBar/ProductBar.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addProductBar", // 添加、修改=商品推荐导航栏   添加、修改=特价导航栏
        name:"addProductBar",
        component:()=>import("../../views/SocialCircle/AddProductBar/AddProductBar.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/specialProduct", //添加特件商品
        name:"specialProduct",
        component:()=>import("../../views/SocialCircle/SpecialProduct/SpecialProduct.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addSpecialProduct", // 修改 特件商品
        name:"addSpecialProduct",
        component:()=>import("../../views/SocialCircle/AddSpecialProduct/AddSpecialProduct.vue"),
        meta:{
            keepAlive: true
        }
    },
] : [];

export default List;
