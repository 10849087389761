export function IsPageNo( index:number,num?:number ) { // 问什么 减1 因为 index 的索引 为数组渲染的索引  所以 的index 是从 0开始的  我们的定义的 pageSize 是从1开始的
    let pageSize = (num || 15) - 1; // 请求的条数  数量 判断 第几页
    if ( index <= pageSize ){
      return 1;
    }
    let IsPageNo = Number( index ) / pageSize
    let PageNo = Math.floor( IsPageNo )
    return PageNo;
  }

// 过滤下拉选择 内容
export function FilterPullItem( data:Array<{[propsData:string]:any}>,key:string ):Array<{[propsData:string]:any}>{
  let newList:Array<{[propsData:string]:any}> = []
  if( data.length ){
    data.forEach( (item,index)=>{
      newList.push({
        title:item[key],
        ...item
      })
    } )
    return newList
  }else{
    return data
  }
  //
}

// 选中数组 active
export function FilterList(data:any[]):any[]{
    let List:any[] = []
    data.forEach( (item,index)=>{
      List.push({
        active:false,
        ...item
      })
    } )
    return List
}

// 选中处理数据
export function ToggleActive(data:any[],idx:number):any[]{
  let List:any[] = []
  data.forEach( (item,index)=>{
    if( index === idx ){
      List.push({
        ...item,
        active:!item.active,
      })
    }else{
      List.push({
        ...item,
        active:false,
      })
    }
  } )
  return List;
}

/**
 * 代取
 * */
// 过滤 订单状态
export function ExpFilterOrder(state:string|number) {
    switch( Number(state) ){
        case 0:
            return "未支付";
        case 1:
            return "无人接单";
        case 2:
            return "已接单";
        case 3:
            return "待确认";
        case 4:
            return "已完成";
        case 5:
            return "异常订单";
        case 6:
            return "已取消";
        case 7:
            return "已删除";
        default:
            return "其他"
    }
}



/**    过滤用户手机号  **/
// 过滤手机号
export function filterPhone(phone) {
    if (phone != null && phone != undefined){
        phone += "";
        let len = phone.length;
        if ( len > 10 ){
            let keyVal = phone.slice(3,9)
            return phone.replace(keyVal,"*****");
        }else{
            let startNum = len - 3 > 0 ? 3 : len - 1 > 0 ? 1 : 0;
            let endNum = (len - startNum - 3 > 0) ? len-3 : (len - startNum - 1) ? len -1 : len;
            let keyVal = phone.slice(startNum,endNum)
            let repeatVal = "";
            for ( let i = 0;i < endNum - startNum; i++ )repeatVal+= "*"
            return phone.replace(keyVal,repeatVal);
        }
    }else{
        return phone;
    }
}

// 过滤名字
export function filterName(name) {
    if ( name != null && name != undefined ){
        let len = name.length;
        let startNum = len - 1 > 0 ? 1 : 0;
        let endNum = (len - startNum - 1 > 0) ? len-1 : len;
        let keyVal = name.slice(startNum,endNum);
        let repeatVal = "";
        for ( let i = 0;i < endNum - startNum; i++ )repeatVal+= "*"
        return name.replace(keyVal,repeatVal);
    }else{
        return name;
    }
}

// 过滤时间
export function filterNum(num = 0) {
    if ( num < 10 ){
        return "0"+num
    }else{
        return num +''
    }
}

// 过滤图片列表
export function filterListToStr(list = []) {
    let str = "";
    list.forEach((item,index)=>{
        if (index){
            str += (","+item)
        }else{
            str = item;
        }
    })
    return str;
}
