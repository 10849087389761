
import Axios from "@/Api/Config/gd"

// 定位信息
export function IpLocation ( body:any ):Promise<any>{
    return (
        Axios({
            url:"/v3/geocode/regeo?parameters",
            method:"GET",
            headers:{},
            params:body
        })
    )
}


// 定位附近 周遭
export function IpNearby ( body:any ):Promise<any>{
    return (
        Axios({
            url:"/v5/place/around?parameters",
            method:"GET",
            headers:{},
            params:body
        })
    )
}

// 搜索定位附近 周遭
export function SearchNearby ( body:any ):Promise<any>{
    return (
        Axios({
            url:"/v5/place/text?parameters",
            method:"GET",
            headers:{},
            params:body
        })
    )
}



