
export default [
	// 快递员信息
    {
        path:"/deliverySuc",
        name:"deliverySuc",
        component:()=>import("../../views/Dispatching/DeliverySuc/DeliverySuc.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 骑手信息
	{
        path:"/bikeSuc",
        name:"bikeSuc",
        component:()=>import("../../views/Dispatching/BikeSuc/BikeSuc.vue"),
        meta:{
            keepAlive: true
        }
    },
]
