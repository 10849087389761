import Storage from "@/util/Storage";
import {GetOpenId, GetSchoolRoom} from "@/Api/Basics";
import {UserStore} from "@/store/modules/User";
import {FilterPullItem} from "@/util/filter";
import {IsJoinGroupChat, JoinGroupChat} from "@/Api/Friend";
import {getUserInfoData} from "@/views/Friend/Server";
import {IpLocation} from "@/Api/Gd";
import {Dialog, Toast} from "vant";
import {UpPhotos} from "@/Api/UpPhoto";

// 请求送件地址
export function getToList() {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(GetSchoolRoom({ userId,token }).then(res=>{
        UserStore.SetRoomList( res.data.buildNumbers )
        return res.data && FilterPullItem(res.data.buildNumbers,"dicCode") || []
    }))
}

// 加入 群
export function joinGroupChat(groupId="") {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(JoinGroupChat({ userId,token },{ groupId }).then(res=>{
        return res.data
    }))
}

// 判断 是否加入群
export function isGroupChat(groupId="") {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(IsJoinGroupChat({ userId,token },{ groupId }).then(res=>{
        if ( res.data ){
            return res.data
        }else{
            return joinGroupChat(groupId).then(res=>res)
        }
    }))
}


// 根据openid获取用户信息
export function getOpenIdUserInfo(openId="") {
   return new Promise((resolve, reject) => {
       GetOpenId(openId).then(res=>{
           let { userId = "",token = "" } = res.data
           if ( userId && token ){
               Storage.SetAllData?.("userId",userId);
               Storage.SetAllData?.("token",token);
           }
           if ( res.message.code == "200" )resolve(res.data);
           else reject();
       })
   }).then(res=>getUserInfoData().then(res=>res))
}

// 根据phone获取用户信息
export function getPhoneUserInfo(openId="") {
    return Promise.resolve(GetOpenId(openId).then(res=>{
        let { userId = "",token = "" } = res.data
        if ( userId && token ){
            Storage.SetAllData?.("userId",userId);
            Storage.SetAllData?.("token",token);
        }
        return res.data
    }))
}

const key = "8d81eca6e74b4bcb93da0a36dae3f317"

// 根据 经纬度 获取 位置信息
export function getAddressData(body:any = {}) {
    let { longitude,latitude } = body
    return Promise.resolve(IpLocation({key,location:`${ longitude },${ latitude }`}).then(res=>{
        return res.regeocode
    }))
}

// 上传 图片
export function upImage(imgArr = []) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const fromData = new FormData()
    fromData.append("functionName","other")
    imgArr.forEach(item=>fromData.append("files",item.file, item.file.name))
    return (
        new Promise((resolve, reject) => {
            if ( imgArr.length > 2 ){
                Dialog({ title:"温馨提示",message:"上传图片数量大于2张，上传速度较慢，是否继续？" }).then(res=>resolve()).catch(cat=>reject())
            }else{
                resolve()
            }
        }).then(res=>{
            Toast.loading({ duration:0,message:"上传中~~~" })
            return Promise.resolve(UpPhotos({ userId,token },fromData).then(res=>{
                Toast.clear()
                return res.data || []
            }))
        }).catch(cat=>{
            Toast.clear()
        })
    )
}
