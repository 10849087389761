

export default [
    { // 平台管理
        path:"/platform",
        name:"platform",
        component:()=>import("../../views/PlatformAdmin/Platform/Platform.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 平台收益
        path:"/platformIncome",
        name:"platformIncome",
        component:()=>import("../../views/PlatformAdmin/PlatformIncome/PlatformIncome.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 月收益
        path:"/monthIncome",
        name:"monthIncome",
        component:()=>import("../../views/PlatformAdmin/MonthIncome/MonthIncome.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 日收益
        path:"/dayIncome",
        name:"dayIncome",
        component:()=>import("../../views/PlatformAdmin/DayIncome/DayIncome.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 骑手收益
        path:"/bikeList",
        name:"bikeList",
        component:()=>import("../../views/PlatformAdmin/BikeList/BikeList.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 平台用户量
        path:"/platformUser",
        name:"platformUser",
        component:()=>import("../../views/PlatformAdmin/PlatformUser/PlatformUser.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 月用户量
        path:"/monthUser",
        name:"monthUser",
        component:()=>import("../../views/PlatformAdmin/MonthUser/MonthUser.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 日用户量
        path:"/dayUser",
        name:"dayUser",
        component:()=>import("../../views/PlatformAdmin/DayUser/DayUser.vue"),
        meta:{
            keepAlive: true
        }
    },
    { // 平台公告管理
        path:"/noticeAdmin",
        name:"noticeAdmin",
        component:()=>import("../../views/PlatformAdmin/NoticeAdmin/NoticeAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 添加平台公告管理
        path:"/addNoticeAdmin",
        name:"addNoticeAdmin",
        component:()=>import("../../views/PlatformAdmin/AddNoticeAdmin/AddNoticeAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },
    { // 学校管理  
        path:"/schoolAdmin", // state=socialCircle 添加自提点  limitTime： 添加限时购时间  type= select选择学校
        name:"schoolAdmin",
        component:()=>import("../../views/PlatformAdmin/SchoolAdmin/SchoolAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 学校管理
        path:"/addSchoolAdmin",
        name:"addSchoolAdmin",
        component:()=>import("../../views/PlatformAdmin/AddSchoolAdmin/AddSchoolAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },
    { // 学校模块管理
        path:"/moduleList", // 1. state= school学校支持模板 | shop社区超市支持模块  2. type = select 选择模块
        name:"moduleList",
        component:()=>import("../../views/PlatformAdmin/ModuleList/ModuleList.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 添加学校模块管理
        path:"/addModuleList",
        name:"addModuleList",
        component:()=>import("../../views/PlatformAdmin/AddModuleList/AddModuleList.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 学校模块管理
        path:"/schoolModuleList", // 1. state= school学校支持模板 | shop社区超市支持模块  2. type = select 选择模块
        name:"schoolModuleList",
        component:()=>import("../../views/PlatformAdmin/SchoolModuleList/SchoolModuleList.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 选择添加学校模块
        path:"/addSchoolModule", // 添加学校模块 school 学校code码 type: school平台模块 shop学校模块
        name:"addSchoolModule",
        component:()=>import("../../views/PlatformAdmin/AddSchoolModule/AddSchoolModule.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 添加轮播图模块管理
        path:"/bannerAdmin",
        name:"bannerAdmin",
        component:()=>import("../../views/PlatformAdmin/BannerAdmin/BannerAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 添加轮播图模块管理
        path:"/addBannerAdmin",
        name:"addBannerAdmin",
        component:()=>import("../../views/PlatformAdmin/AddBannerAdmin/AddBannerAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },
]
