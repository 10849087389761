

export default [
    { // 表白墙
        path:"/whiteWall",
        name:"whiteWall",
        component:()=>import("../../views/Other/Confession/Confession.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 好友推荐
        path:"/friendRecommend",
        name:"friendRecommend",
        component:()=>import("../../views/Other/FriendRecommend/FriendRecommend.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 闪电约会
        path:"/firstLine",
        name:"firstLine",
        component:()=>import("../../views/Other/SpeedDating/SpeedDating.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 盲盒瞎猜
        path:"/blindBox",
        name:"blindBox",
        component:()=>import("../../views/Other/GuessBox/GuessBox.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 盲盒中奖
        path:"/lotteryBox",
        name:"lotteryBox",
        component:()=>import("../../views/Other/LotteryBox/LotteryBox.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 查看 盲盒 参加人列表
        path:"/guessJoinList",
        name:"guessJoinList",
        component:()=>import("../../views/Other/GuessJoinList/GuessJoinList.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 盲盒瞎猜
        path:"/guessBoxAdmin",
        name:"guessBoxAdmin",
        component:()=>import("../../views/Other/GuessBoxAdmin/GuessBoxAdmin.vue"),
        meta:{
            keepAlive: true
        }
    },
]
