
import Storage from "@/util/Storage"
import Url from "./url"
import {FriendsApplyList, UpMessageList, FriendsApplyListNum} from "@/views/Message/Server";
// eslint-disable-next-line
// @ts-ignore
import moment from "moment";
import {
    filterChatData,
    filterChatList,
    filterGroupChatData,
    filterList,
    filterPath,
    filterUnread
} from "@/Api/Socket/util";
import {MessageStore} from "@/views/Message/Store";

export default class Socket {
    timeOut = 1000 * 60 * 5;
    timeObj:any = null;
    delay = 1000 * 20; //重连 延迟
    count = 0;
    maxCount = 20;
    socket:any = null;
    url = ""
    heartbeat = false

    constructor(state = "group",heartbeat?:boolean) {
        /**
         * id 心跳id
         * state 链接类型
         * heartbeat 是否开启心跳
         * setData 更新视图的方法
         * */
        const userId = Storage.GetData_ && Storage.GetData_("userId");
        const token = Storage.GetData_ && Storage.GetData_("token");

        this.url = `${Url[state]}${userId}/${token}`
        if (heartbeat) this.heartbeat = heartbeat;

        this.init()
        this.initData()
        this.handleChatList()
        this.handleApplyFriendsList()
        this.handleGetApplyList()
    }

    init(){
        this.socket =  new WebSocket(this.url)
        this.socket.addEventListener("open",this.handleOpen)
        this.socket.addEventListener("error",this.handleError)
        this.socket.addEventListener("message",this.handleMessage)
        this.count += 1;
    }

    handleOpen=(e:any)=>{
        console.log("链接成功")
        if ( !this.heartbeat ) this.handleHeartbeat();
        this.count = 0;
    }

    handleError=(e:any)=>{
        console.log(e,"链接失败")
        this.close()
        this.socket.removeEventListener("open",this.handleOpen)
        this.socket.removeEventListener("error",this.handleError)
        this.socket.removeEventListener("message",this.handleMessage)
        cancelAnimationFrame(this.timeObj)
        // clearInterval(this.timeObj)
        let time = setTimeout(()=>{
            if ( this.count < this.maxCount )this.init();
            else this.close();
            clearTimeout( time )
        },this.delay)
    }

    handleMessage=(e:any)=>{
        let reg = /\{\}/g
        if ( !reg.test(e.data) ){
            let commentData = { createTime:moment().format("YYYY-MM-DD HH:mm:ss"), }
            try{
                let data = filterGroupChatData( { ...JSON.parse(e.data),...commentData } )
                this.handleDriveView(data)
            }catch (e) {
                let data = filterGroupChatData({ ...eval(e.data),...commentData } )
                this.handleDriveView(data)
            }
        }
    }

    // 心跳
    handleHeartbeat(){
        this.timeObj = setInterval(()=>{
            if (this.socket.readyState == 3) {
                this.close()
                this.init()
            }else
                this.handleHeartbeat();
        },this.delay)
    }

    initData(){
        let chatList = JSON.parse(Storage.GetData_?.("chatList") || "[]")
        let applyList = JSON.parse(Storage.GetData_?.("applyList") || "[]")
        let applyListNum = Storage.GetData_?.("applyListNum");
        applyListNum = applyListNum != null && applyListNum || 0
        let chatListNum = Storage.GetData_?.("chatListNum");
        chatListNum = chatListNum != null && chatListNum || 0

        MessageStore.SetNewestMessageList(chatList)
        MessageStore.SetNewestMessageNum( (chatListNum | 0) )
        MessageStore.SetNewestMessageFriendStatus(applyList)
        MessageStore.SetNewestMessageFriendStatusNum( (applyListNum | 0) )
        this.handleComputedUnread()
    }

    handleDriveView(data:any){
        let { chatStatus = 0,groupId = "",friendId = "" } = data;
        let reg = /chat/gi,
            reg1 = /chat/gi,
            reg2 = /newFriends/gi,
            status = (chatStatus | 0);
        let { hash = "" } = window.location
        try{
            let { id = "" } = filterPath()
            // 区分：消息列表 消息未读数量 区分
            if ( status == 4 || status == 5 ){
                if(status == 4) data = filterChatData(data);
                if ( status == 5 && reg1.test(hash) && id == groupId ){
                    MessageStore.SetNewestMessageGroupChat(data)
                    // 进行列表数据处理
                    let List = filterChatList(data,MessageStore.getNewestMessageList,true)
                    Storage.SetData_?.("chatList",JSON.stringify(List))
                    MessageStore.SetNewestMessageList(List)
                } else if ( status == 4 && reg.test(hash) && id == friendId ){
                    MessageStore.SetNewestMessageChat(data)
                    // 进行列表数据处理
                    let List = filterChatList(data,MessageStore.getNewestMessageList,true)
                    Storage.SetData_?.("chatList",JSON.stringify(List))
                    MessageStore.SetNewestMessageList(List)
                } else{
                    let List = filterChatList(data,MessageStore.getNewestMessageList)
                    // 进行本地缓存
                    Storage.SetData_?.("chatList",JSON.stringify(List))
                    MessageStore.SetNewestMessageList(List)
                    MessageStore.SetNewestMessageNum((MessageStore.getNewestMessageNum | 0) + 1 || 0)
                    this.handleComputedUnread()
                }
            } else{
                if ( reg2.test(hash) && status < 4 ){
                    // 刷新出 最新的数据
                    let List:any = filterChatList(data,MessageStore.getNewestMessageFriendStatus);
                    // 进行本地缓存
                    Storage.SetData_?.("applyList",JSON.stringify(List))
                    MessageStore.SetNewestMessageFriendStatus(List)
                } else{
                    let List:any = filterChatList(data,MessageStore.getNewestMessageFriendStatus);
                    // 进行本地缓存
                    Storage.SetData_?.("applyList",JSON.stringify(List))
                    MessageStore.SetNewestMessageFriendStatus(List)
                    MessageStore.SetNewestMessageFriendStatusNum( ( MessageStore.getNewestMessageFriendStatusNum | 0 ) + 1 );
                    this.handleComputedUnread()
                }
            }
        }catch (e) {
            console.info("接受数据处理异常")
        }
        if (process.env.NODE_ENV == "development") console.log(data);

    }

    // 消息聊天列表
    handleChatList(){
        UpMessageList().then(res=>{
            let List:any[] = filterList(MessageStore.getNewestMessageList,(res.userChatData || []))
            // console.log(List)
            MessageStore.SetNewestMessageList(List)
            MessageStore.SetNewestMessageNum(res.unreadTotal || 0)
            this.handleComputedUnread()
        })
    }

    // 申请好友状态
    handleApplyFriendsList(){
        FriendsApplyList().then(res=>{
            MessageStore.SetNewestMessageFriendStatus(res || [])
            // console.log(res);
        })
    }

    // 获取申请好友状态未读数量
    handleGetApplyList(){
        FriendsApplyListNum().then(res=>{
            MessageStore.SetNewestMessageFriendStatusNum(res.isReadCount || 0)
            this.handleComputedUnread()
        })
    }

    // 计算总共的未读数量
    handleComputedUnread(){
        let Num = (MessageStore.getNewestMessageFriendStatusNum | 0) + (MessageStore.getNewestMessageNum | 0)
        Storage.SetData_?.("applyListNum",(MessageStore.getNewestMessageFriendStatusNum | 0) + '')
        Storage.SetData_?.("chatListNum",(MessageStore.getNewestMessageNum | 0) + '')
        MessageStore.SetNewestMessageNavNum(Num)
    }

    // 计算未读数量
    handleUnreadNum(){
        let Num = filterUnread(MessageStore.getNewestMessageList,"chat")
        MessageStore.SetNewestMessageNum(Num)
        this.handleComputedUnread()
    }

    close=()=>{
        try{
            cancelAnimationFrame(this.timeObj)
            clearInterval(this.timeObj)
        }catch (e) {
            console.info("清除心跳失败" + e)
        }
        this.socket.close()
    }
}
