

export default [
	//同学圈
    {
        path:"/friendCircle",
        name:"friendCircle",
        component:()=>import("../../views/Friend/FriendCircle/FriendCircle.vue"),
        meta:{
            keepAlive: true
        },
    },
	// 同学圈分享详情
    {
        path:"/friendSuccess",
        name:"friendSuccess",
        component:()=>import("../../views/Friend/FriendSuccess/FriendSuccess.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 查看一级评论
	{
        path:"/friendComment",
        name:"friendComment",
        component:()=>import("../../views/Friend/FriendComment/FriendComment.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 发布朋友圈
	{
        path:"/issueFriend",
        name:"issueFriend",
        component:()=>import("../../views/Friend/IssueFriend/IssueFriend.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 谁可以查看
	{
        path:"/friendExamine",
        name:"friendExamine",
        component:()=>import("../../views/Friend/FriendExamine/FriendExamine.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 我的发布
	{
        path:"/myFriend",
        name:"myFriend",
        component:()=>import("../../views/Friend/MyFriend/MyFriend.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 添加朋友列表
	{
        path:"/addFriend",
        name:"addFriend",
        component:()=>import("../../views/Friend/IsFriend/IsFriend.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 聊天
	{
        path:"/myFriendCircle",
        name:"myFriendCircle",
        component:()=>import("../../views/Friend/MyFriendCircle/MyFriendCircle.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 附近
	{
        path:"/friendAddress",
        name:"friendAddress",
        component:()=>import("../../views/Friend/FriendAddress/FriendAddress.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 申请添加朋友
	{
        path:"/applyFriend",
        name:"applyFriend",
        component:()=>import("../../views/Friend/ApplyFriend/ApplyFriend.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 我的好友
	{
        path:"/myFriends",
        name:"myFriends",
        component:()=>import("../../views/Friend/MyFriends/MyFriends.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 新朋友
	{
        path:"/newFriends",
        name:"newFriends",
        component:()=>import("../../views/Friend/NewFriends/NewFriends.vue"),
        meta:{
            keepAlive: true
        }
    },
	// 添加朋友
	{
        path:"/findFriend",
        name:"findFriend",
        component:()=>import("../../views/Friend/FindFriend/FindFriend.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/searchFriend",
        name:"searchFriend",
        component:()=>import("../../views/Friend/SearchFriend/SearchFriend.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/schoolCampus",
        name:"schoolCampus",
        component:()=>import("../../views/Friend/SchoolCampus/SchoolCampus.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addressBook",
        name:"addressBook",
        component:()=>import("../../views/Friend/AddressBook/AddressBook.vue"),
        meta:{
            keepAlive: true
        }
    },
]
