import ZoomPageType from "@/imp/PageIndex";

export async function isBarShow( That:any ) { // ?barShow=true
    let data = That.$route.query
    if ( "barShow" in data ){
        That["barShow"] = false
    }else{
        That["barShow"] = true
    }
}

import { Vue } from "vue-property-decorator";

export default class ZoomPage extends Vue implements ZoomPageType{
    barShow = true
    loadingShow = true

    constructor() {
        super();
        isBarShow(this).then().catch();
    }

    mounted(){
        this.loadingShow = false
    }

    created(){
        let phone = navigator.userAgent;
        let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1;
        if ( isAndroid ){
            // window["AndroidToHtml"] = function () {
            //     // _this.freshCash();
            // }
        }else if ( isiOS ){
            // window["AndroidToHtml"] = function () {
            //     // _this.freshCash();
            // }
        }
    }

    activated(){
        //
    }
}
