


export default [
	// 实名认证
    {
        path:"/authentication",
        name:"authentication",
        component:()=>import("../../views/Authent/AuthentList.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 认证信息
    {
        path:"/autherIdentity",
        name:"autherIdentity",
        component:()=>import("../../views/Authent/AuthentSuc.vue"),
        meta:{
            keepAlive:true
        }
    },
	// 提交认证信息
    {
        path:"/auther",
        name:"auther",
        component:()=>import("../../views/Authent/Authent.vue"),
        meta:{
            keepAlive:true
        }
    }
]