import Storage from "@/util/Storage";
import {myFriendAboutUnread} from "@/Api/Friend";
import {GetUserData} from "@/Api/Basics";
import Stroage from "@/util/Storage";


/** 获取关于我的朋友圈 */
export function getMyAboutUnread() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( myFriendAboutUnread({ userId,token }).then(res=>{
        return res.data
    }) )
}

/** 获取个人信息 */
export function getUserInfoData() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( GetUserData({ userId,token }).then(res=>{
        Stroage.SetData_ && Stroage.SetData_("UserData",JSON.stringify( res.data ))
        if ( res.data["openId"] ) Stroage.SetData_?.("OpenId",res.data["openId"]);
        return res.data
    }) )
}
