import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Storage from "../util/Storage"
import Index from "../views/Index.vue";
import Basics from "./paths/Basics"
import Authent from "./paths/Authent"
import Coupon from "./paths/Coupon"
import Address from "./paths/Address"
import Express from "./paths/Express"
import Shop from "./paths/Shop"
import Help from "./paths/Help"
import Hand from "./paths/Hand"
import Class from "./paths/Class"
import SchoolSuc from "@/router/paths/SchoolSuc"
import Friend from "@/router/paths/Friend";
import Login from "@/router/paths/Login"
import Message from "@/router/paths/Message"
import Money from "@/router/paths/Money";
import Dispatching from "@/router/paths/Dispatching";
import ShopAdmin from "@/router/paths/ShopAdmin";
import Group from "@/router/paths/Group";
import Other from "@/router/paths/Other";
import PlatformAdmin from "@/router/paths/PlatformAdmin"
import SocialCircle from "@/router/paths/SocialCircle"
import {IndexStore} from "@/store/modules/Index";
import {CouponStore} from "@/store/modules/Basics/Coupon";
import {UserStore} from "@/store/modules/User";
import {Toast} from "vant";
import {getUserInfoData} from "@/views/Friend/Server";
import { getPhoneUserInfo } from '@/server/index';

const originalPush = VueRouter.prototype.push;
// eslint-disable-next-line
// @ts-ignore
VueRouter.prototype.push = function (location:any, resolve:any, reject:any) {
    if ( resolve || reject ) return originalPush.call(this, location, resolve, reject);
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "index",
        component: Index,
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("../views/Home.vue"),
               meta: {
                requiresAuth: true,
                keepAlive: false,
            }
            },
            {
                path: "/user",
                name: "user",
                component: () => import("../views/User.vue")
            },
            {
                path: "/list",
                name: "list",
                component: () => import("../views/List.vue")
            },
            {
                path: "/friendsCircle",
                name: "friendsCircle",
                component: () => import("../views/Friend/FriendCircle/FriendCircle.vue")
            },
            {
                path: "/messageList",
                name: "messageList",
                component: () => import("../views/Message/MessageList/MessageList.vue")
            }
        ],
    },
    ...Basics,
    ...Authent,
    ...Coupon,
    ...Address,
    ...Express,
    ...Shop,
    ...Help,
    ...Hand,
    ...Class,
    ...SchoolSuc,
    ...Friend,
    ...Login,
    ...Message,
    ...Money,
    ...Dispatching,
    ...ShopAdmin,
    ...Group,
    ...Other,
    ...PlatformAdmin,
    ...SocialCircle,
];

if (process.env.NODE_ENV == "development") {
    console.log("页面数量 " + routes.length);
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, sacedPosition) {
        let time: any = setTimeout(() => {
            if (sacedPosition) {
                return sacedPosition
            } else {
                return {x: 0, y: 0}
            }
            clearTimeout(time)
        })
    },
});

/** 链接参数 外卖请求状态 */
function getShopState(state: string, title?: string) {
    switch (Number(state)) {
        case 0:
            IndexStore.SetShopState({
                state: 0,
                title: title || '校园外卖',
                type: 0
            })
            break
        case 1:
            IndexStore.SetShopState({
                state: 1,
                title: title || '校园百货',
                type: 1
            })
            break
        case 2:
            IndexStore.SetShopState({
                state: 2,
                title: title || '零食铺子',
                type: 2
            })
            break
        case 3:
            IndexStore.SetShopState({
                state: 3,
                title: title || '新鲜水果',
                type: 3
            })
            break
    }
    CouponStore.SetSelCoupon({})
    UserStore.SetSelAddress({})
}

/** barShow 是否显示 */
function IsBarShow(to: any, from: any, next: any) {
    let url = window.location.origin
    if (/\?/g.test(to.fullPath)) {
        url += ("/#" + to.fullPath + "&barShow=true")
    } else {
        url += ("/#" + to.fullPath + "?barShow=true")
    }
    if ( from.query["linkState"] && from.query["linkState"] == "socialize" ){
        to.query["linkState"] = "socialize"
        url +=  "&linkState=socialize"
    }
    // window.location.pathname
    if (!to.query["barShow"]) next();
    if (to.name && from.name && to.name != from.name && from.query["barShow"] && !to.query["barShow"]) {
        to.query["barShow"] = "true"
        next()
        let time = setTimeout(() => {
            window.history.replaceState('', '', url)
            clearTimeout(time)
        }, 400)
    } else {
        next()
    }
}

// shopType:外卖类型 shopTitle:外卖标题  barShow:标题是否显示  userId token
router.beforeEach((to, from, next) => {
    console.log(to, from)
    let {query} = to
    if (JSON.stringify(query) != "{}") {
        /** barShow 标题是否先显示 */
        let {userId, token,openId,longitude="",latitude=""} = query
        try {
            let locationData = {  };
            if ( longitude ) locationData["longitude"] = longitude;
            if ( latitude ) locationData["latitude"] = latitude;
            if (Object.keys(locationData).length) Storage.SetData_?.("locationData",JSON.stringify(locationData));

            if (userId && token) {
                /** userId && token */
                Storage.SetAllData && Storage.SetAllData("userId", userId as string);
                Storage.SetAllData && Storage.SetAllData("token", token as string);
                IndexStore.SetLoading(true)
                /** shopType 外卖请求状态 */
                if (query["shopType"]) {
                    getShopState(query["shopType"] as string, query["shopTitle"] as string && query["shopTitle"] as string || '')
                }
                // 通讯录
                if (query["phoneHook"]) {
                    try {
                        try {
                            let PhoneHook = JSON.parse(query["phoneHook"] as string)
                            Storage.SetAllData?.("phoneHook", JSON.stringify(PhoneHook) as string)
                        } catch (e) {
                            let PhoneHook = eval(query["phoneHook"] as string)
                            Storage.SetAllData?.("phoneHook", JSON.stringify(PhoneHook) as string)
                        }
                    } catch (e) {
                        console.info("转换失败")
                    }
                }
                /** 个人信息 */
                let data = Storage.GetData_ && JSON.parse(Storage.GetData_("UserData"))
                if (!data) {
                    getUserInfoData().then(res => {
                        IsBarShow(to, from, next)
                    })
                } else {
                    IsBarShow(to, from, next)
                }
            } else if ( openId ){
                try{
                    getPhoneUserInfo(openId as string).then(res=>{
                        Storage.SetAllData && Storage.SetAllData("OpenId", openId as string);
                        IndexStore.SetLoading(true)
                        IsBarShow(to, from, next)
                    })
                }catch (e) {
                    console.info(e)
                }
            }else {
                let userId = Storage.GetData_?.("userId")
                let token = Storage.GetData_?.("token")
                if ( userId && token ){
                    IsBarShow(to, from, next)
                }else{
                    // 判断 是否登录  延迟
                    let time = setInterval(()=>{
                        let userId = Storage.GetData_?.("userId")
                        let token = Storage.GetData_?.("token")
                        if ( userId && token ){
                            IsBarShow(to, from, next)
                            clearInterval(time)
                        }
                    },1000)
                }
            }
        } catch (e) {
            IsBarShow(to, from, next)
        }
    } else {
        // 拦截 userId token
        let userId = Storage.GetData_?.("userId")
        let token = Storage.GetData_?.("token")
        if ( userId && token ){
            IsBarShow(to, from, next)
        }else{
            let num = 0;
            let time = setInterval(()=>{
                userId = Storage.GetData_?.("userId")
                token = Storage.GetData_?.("token")
                if ( userId && token ){
                    IsBarShow(to, from, next)
                    clearInterval(time)
                }else{
                    if ( num > 1000 ){
                        IsBarShow(to, from, next)
                        clearInterval(time)
                    }
                    num ++;
                }
            },10)
        }
    }
    Toast.clear();
})

export default router;
