import { Basics,SocializeBasics,urlState } from "../Config/url"
import axios from "axios"
import {Notify} from "vant"
import {errorDispose} from "@/Api/Config/propsUtil";

let Axios = axios.create()
Axios.defaults.baseURL = (urlState == "sanyixiaoyuan" || urlState == "all") ? Basics : SocializeBasics;
Axios.defaults.headers.post["type"] = "wxpublic"

Axios.defaults.timeout = (100*1000)

Axios.interceptors.request.use(config=>{
    return config
},error=>{
    Promise.reject(error)
})

Axios.interceptors.response.use(response=>{
    return errorDispose(response,"base");
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
})



interface UpPhotoHeadType<T=string>{
    userId:T | number;
    token:T
}
// UpPhotoType
// 单文件添加
export default function UpPhoto( headers:UpPhotoHeadType,body:any ):Promise<any>{
    return (
        Axios({
            url:"/upload/uploadFile",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 多文件 添加
export function UpPhotos( headers:UpPhotoHeadType,body:any ):Promise<any>{
    return (
        Axios({
            url:"/upload/uploadFiles",
            method:"POST",
            headers,
            data:body
        })
    )
}

