import Vue from "vue";
import Vuex from "vuex";
import { IndexType } from "@/store/indexType"
import { HomeType } from "./modules/Home"
import { UserType } from "./modules/User"
import { AuthentType } from "./modules/Basics/Authent"
import { CouponType } from "./modules/Basics/Coupon"
import { ExpressType } from "./modules/Express/Express"
import ExpressTypes from "@/views/Express/indexType"
import { ShopType } from "./modules/Shop/Shop"
import { ShopSucType } from "./modules/Shop/ShopSuc"
import { HelpType } from "./modules/Help/Help"
import { HandType } from "./modules/Hand/Hand"
import FriendIndexType from "@/views/Friend/indexType"
import MoneyType from "@/views/Money/indexType";
import ShopDataType from "@/views/Shop/indexType";
import DispatchType from "@/views/Dispatching/indexType";
import GroupType from "@/views/Group/IndexType";
import MessageType from "@/views/Message/IndexType";
import GlobalType from "@/store/modules/Global/indexType"
import ShopAdmin from "@/views/ShopAdmin/indexType";
import PlatformAdmin from "@/views/PlatformAdmin/indexType"
import SocialCircle from "@/views/SocialCircle/indexType"

Vue.use(Vuex);

interface VueType{
  Index:IndexType,
  Home:HomeType<string>,
  User:UserType<string>,
  Authent:AuthentType<string>,
  Coupon:CouponType,
  Express:ExpressType,
  Shop:ShopType,
  ShopSuc:ShopSucType,
  Help:HelpType,
  Hand:HandType,
  Friend:FriendIndexType,
  Money:MoneyType
  ShopDataType:ShopDataType,
  DispatchType:DispatchType,
  GroupType:GroupType,
  MessageType:MessageType,
  ExpressTypes:ExpressTypes,
  GlobalType:GlobalType,
  ShopAdmin:ShopAdmin,
  PlatformAdmin:PlatformAdmin,
  SocialCircle:SocialCircle
}

export default new Vuex.Store<VueType>({});

// state: {},
//   mutations: {},
//   actions: {},
//   modules: {}
// modules: {
//   // IndexType
// }
// import FriendCircleStoreType from "@/views/Friend/FriendCircle/Store/indexType"
// import FriendSuccessType from "@/views/Friend/FriendSuccess/Store/indexType";
// import IssueFriendStoreType from "@/views/Friend/IssueFriend/Store/indexType";
// import MyFriendStoreType from "@/views/Friend/MyFriend/Store/indexType";
// Friend:FriendCircleStoreType,
// FriendSuccess:FriendSuccessType,
// FriendIssue:IssueFriendStoreType,
// FriendMy:MyFriendStoreType
