import Storage from "@/util/Storage";


export function filterGroupChatData(data: any) {
    const userId = Storage.GetData_?.("userId");
    if (data.userId == userId)
        data.state = "right";
    else
        data.state = "left";
    return data;
}


export function filterChatData(data: any) {
    const userId = Storage.GetData_?.("userId");
    if (data['receivedId'] != userId)
        data.state = "right";
    else
        data.state = "left";
    return data;
}

// 过滤消息列表
export function filterChatList(newData: any = {}, list: any[] = [], clear = false) {
    let {chatStatus = 0, friendId = "", groupId = "", userId = ""} = newData;
    if (chatStatus == 4) {
        let key = list && list.findIndex(a => a.friendId == friendId);
        if (key < 0) {
            list.unshift({
                ...newData,
                unreadCount: clear && 0 || 1,
                isRead: 0,
                friendName: newData.userName || '',
                sentTime: newData.createTime || new Date()
            })
        } else {
            let keyData: any = list.splice(key, 1)[0];
            keyData.unreadCount = clear ? 0 : ((keyData.unreadCount | 0) + 1);
            keyData.content = newData.content || ''
            keyData.sentTime = newData.createTime || new Date()
            keyData.type = newData.type || 0
            list.unshift(keyData)
        }
    } else if (chatStatus < 4) {
        let key = list && list.findIndex(a => a.friendId == userId);
        if (key < 0) {
            list.unshift({
                ...newData,
            })
        } else {
            let keyData: any = list.splice(key, 1)[0];
            keyData.content = newData.content
            keyData.state = newData.state
            list.unshift(keyData)
            // let status = Number( chatStatus || 0 );
            // switch ( status ) {
            //     case 0:
            //         break;
            // }
        }
    } else {
        let key = list && list.findIndex(a => a.groupId == groupId);
        if (key < 0) {
            list.unshift({
                ...newData,
                unreadCount: clear && 0 || 1,
                isRead: 0,
                sentTime: newData.createTime || new Date()
            })
        } else {
            let keyData: any = list.splice(key, 1)[0];
            keyData.unreadCount = clear ? 0 : ((keyData.unreadCount | 0) + 1);
            keyData.content = newData.content || ''
            keyData.sentTime = newData.createTime || new Date()
            keyData.type = newData.type || 0
            keyData.state = newData.state || ''
            keyData.userName = newData.userName || ''
            list.unshift(keyData)
        }
    }
    return list;
}

// 过滤 已有数据列表
export function filterList(oldList: any[] = [], newList = [], state = "chat") {
    let List: any[] = [],
        endList: any[] = [];
    newList && newList.forEach((item: any, index: number) => {
        let key = oldList.findIndex((a: any) =>(a.groupId && item.groupId) && a.groupId == item.groupId || a.friendId == item.friendId)
        if (key < 0) {
            if (newList[key] && newList[key]['unreadCount']) {
                List.push(item)
            } else {
                endList.push(item)
            }
        } else {
            item.unreadCount = newList[key] && newList[key]['unreadCount'] || 0
            if (newList[key] && newList[key]['unreadCount']) {
                let keyData: any = oldList.splice(key, 1)[0];
                if (state == 'chat') {
                    //
                }
                oldList.unshift(keyData)
            }
        }
    })
    return [...List, ...oldList, ...endList]
}

// 过滤未读数量
export function filterUnread(list: any[] = [], state = "chat") {
    let Num = 0;
    list.forEach((item, index) => {
        if (state == "chat") {
            if (item['unreadCount'] > 0) Num++;
        }
    })
    return Num;
}

// 将跳转的 聊天 提示清空
export function filterNum(list: any[] = [], key = 0) {
    if (list && list[key]) {
        let keyData: any = list.splice(key, 1)[0];
        keyData['unreadCount'] = 0;
        if ('isRead' in keyData) keyData['isRead'] = 1
        list.unshift(keyData)
    }
    return list
}

// 过滤 路由
export function filterPath() {
    let {hash = ""} = window.location
    let newData: any = {}
    let routerStr = hash.split("?");
    if (routerStr && routerStr[1]) {
        let routerArr = routerStr[1].split("&")
        routerArr.forEach(item => {
            let data = item.split("=")
            newData[data[0]] = data[1]
        })
    }
    return newData
}
