
export const basics = [
    "app/user/queryNewUserCount",
    "app/user/eachSchoolUser",
    "app/user/eachMonthUser",
    "app/user/everyDayUser",
    "school/getList", // 学校列表
]

export const exp = [
    "campus/income/dayIncome",
    "campus/income/incomeBySchool",
    "campus/income/everyMonthIncome",
    "campus/income/everyDayIncome",
]

export const shop = [
    "app/order/income/schoolIncomeList",
    "app/order/income/monthIncome",
    "app/order/income/dayIncome",
    "app/order/income/dateIncome",

    "dispatch/riderWageList"
]

export default [
    ...basics,
    ...exp,
    ...shop
]
