
interface ObjType {
    SetData?( name:string,data:string ):boolean;
    GetData?( name:string ):any ;
    DelData?( name:string ):Promise<boolean>;
    Clear?():Promise<boolean>;
    GetData_?( name:string ):string | undefined | null | any;
    SetData_?( name:string,data:string ):boolean;
    DelData_?( name:string ):void;

    SetAllData?(name:string,data:string):any;
    ClearAll?():void;
}

let  Obj : ObjType = {};

Obj.SetData = function( name:string,data:string ){
    if( name === "userId" || name === "token" || name === "OpenId" ){
        if( !!data && "btoa" in window ){
            data = data + " QianGeGeWoAiNi521"
            data = btoa( data )
            window.localStorage.setItem( name,data )
        }else{
            if ( data ){
                window.localStorage.setItem( name,data )
            }else{
                window.localStorage.setItem( name,'' )
            }
        }
    }else{
        data = JSON.stringify( data ) || ''
        window.localStorage.setItem( name,data )
    }
    return true
}
Obj.GetData = function( name:string ){
    let data = window.localStorage.getItem( name ) as string
    if( name === "userId" || name === "token" || name === "OpenId" ){
        if( Boolean(data) && "atob" in window ){
            data = atob( data )
            data = data.split(" ")[0]
        }
    }else{
        try {
            data = JSON.parse( data )
        }catch (e) {
            console.info("转换失败")
        }
    }
    return data
}
Obj.DelData = function( name:string ){
    window.localStorage.removeItem( name )
    return Promise.resolve(true)
}
Obj.Clear = function(){
    window.localStorage.clear()
    return Promise.resolve(true)
}

/**  一次性存储  */

Obj.SetData_ = function( name:string,data:string ){
    if( name === "userId" || name === "token" || name === "OpenId" ){
        if( !!data && "btoa" in window ){
            data = data + " QianGeGeWoAiNi521"
            data = btoa( data )
            window.sessionStorage.setItem( name,data )
        }else{
            if ( data ){
                window.sessionStorage.setItem( name,data )
            }else{
                window.sessionStorage.setItem( name,'' )
            }
        }
    }else{
        window.sessionStorage.setItem( name,data )
    }
    return true
}
Obj.GetData_ = function( name:string ):string{
    let data = window.sessionStorage.getItem( name ) as string
    if( name === "userId" || name === "token" || name === "OpenId" ){
        if( !!data && "atob" in window ){
            data = atob( data )
            data = data.split(" ")[0]
        }
    }
    return  data
}
Obj.DelData_ = function(name:string){
    window.sessionStorage.removeItem( name );
}

/** 多处存储 */
Obj.SetAllData = function (name:string,data:string) {
    if( name === "userId" || name === "token" || name === "OpenId" ){
        if( !!data && "btoa" in window ){
            Obj.SetData_?.(name,data)
            Obj.SetData?.(name,data)
        }else{
            if ( !data )data = "";
            window.sessionStorage.setItem( name,data )
            window.localStorage.setItem( name,data )
        }
    }else{
        if ( !data )data = "";
        window.sessionStorage.setItem( name,data )
        window.localStorage.setItem( name,data )
    }
}

Obj.ClearAll = function () {
    window.sessionStorage.clear()
    window.localStorage.clear()
}

export default Obj;
