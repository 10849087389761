// production   development
const keyStr = "development"

/**   重新定向跳转  */
// test official 备注：只针对三易校园从定向配置  如果需支持三易社圈需要单抽方法来解决
export const ServerStatus:any = "official"

/**   打包请求路径  */
// all:综合打包域名 根据路由参数linkState=socialize；来区分是否为社圈请求路由 默认情趣域名为：sanyixiaoyuan
// sanyixiaoyuan:只打包三易校园请求域名
// sanyishequ：只打包三易社圈请求域名
export const urlState:any = "sanyishequ";

// 三易校园
export let Basics  =  process.env.NODE_ENV === keyStr ? "https://test.community.31shequ.com/base" : "https://community.31shequ.com/base"
export let Express  =  process.env.NODE_ENV === keyStr ? "https://test.community.31shequ.com/mailDemand" : "https://community.31shequ.com/mailDemand"
export let Shop =  process.env.NODE_ENV === keyStr ? "https://test.community.31shequ.com/shop" : "https://community.31shequ.com/shop"
export let Help =  process.env.NODE_ENV === keyStr ? "https://test.community.31shequ.com/helpAll" : "https://community.31shequ.com/helpAll"
export let Second =  process.env.NODE_ENV === keyStr ? "https://test.community.31shequ.com/friend" : "https://community.31shequ.com/friend"


// 三易社圈
export let SocializeBasics  =  process.env.NODE_ENV === keyStr ? "https://community.31shequ.com/base" : "https://community.31shequ.com/base"
export let SocializeExpress  =  process.env.NODE_ENV === keyStr ? "https://community.31shequ.com/mailDemand" : "https://community.31shequ.com/mailDemand"
export let SocializeShop =  process.env.NODE_ENV === keyStr ? "https://community.31shequ.com/shop" : "https://community.31shequ.com/shop"
export let SocializeHelp =  process.env.NODE_ENV === keyStr ? "https://community.31shequ.com/helpAll" : "https://community.31shequ.com/helpAll"
export let SocializeSecond =  process.env.NODE_ENV === keyStr ? "https://community.31shequ.com/friend" : "https://community.31shequ.com/friend"
