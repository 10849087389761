import store from "../../index"
import { CouponItem } from "../../../Type/index"
import { Module,getModule,VuexModule,Mutation } from "vuex-module-decorators"

export interface CouponType<T=string>{
    CouponList:Array<CouponItem>
    SelCoupon:CouponItem
    SelCouponType:T;
    SelCouponList:CouponItem[];
    SetCouponList(data?:Array<CouponItem>):void;
    SetSelCoupon(data:CouponItem):void;
    SetSelType(data:T):void;
    SetSelList(data:CouponItem[]):void;
}

@Module({
    name:"Coupon",
    store,
    dynamic:true
})
export default class Coupon extends VuexModule implements CouponType{
    CouponList: CouponItem<string, number>[] = []
    SelCoupon:CouponItem = {}
    SelCouponType = "0"
    SelCouponList: CouponItem<string, number>[] = []

    get GetCouponList(){
        return this.CouponList
    }
    @Mutation
    SetCouponList(data:Array<CouponItem>){
        this.CouponList = data
    }
    // 获取 选择优惠卷
    get GetSelCoupon(){
        return this.SelCoupon
    }
    @Mutation
    SetSelCoupon(data: CouponItem<string, number>): void {
        this.SelCoupon = data
    }
    // 选择优惠卷 类型
    get GetSelType(){
        return this.SelCouponType
    }
    @Mutation
    SetSelType(data: string): void {
        this.SelCouponType = data
    }
    //选择地址列表
    get GetSelList(){
        return this.SelCouponList
    }
    @Mutation
    SetSelList(data: CouponItem<string, number>[]): void {
        this.SelCouponList = data
    }
}

export const CouponStore = getModule(Coupon)