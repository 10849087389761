

import Axios from "axios"
import { Notify } from "vant"

const axios = Axios.create()
axios.defaults.baseURL = "https://restapi.amap.com"


axios.defaults.timeout = (10*1000)

// 请求前
axios.interceptors.request.use( config=>{
    return config
},error=>{
    return Promise.reject(error)
} )
// 请求后
axios.interceptors.response.use( response=>{
    if ( response.data.info && response.data.info == "OK" ){
        return response.data
    }else{
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
        return response.data
    }
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
} )


export default axios;
