
import Axios  from "../Config/friend"
import {
    HeaderType,
    AllFriendBody,
    GiveBody,
    FriendSucBody,
    FriendSucCommentBody,
    friendSucCommentListType, issueFriendType, findFriendSucType, myFriendsCircleUpDataType, IsJoinGroupChatType
} from "./interfaceType"
import {setCommentType} from "@/views/Friend/FriendSuccess/Store/indexType";

// 查询朋友圈
export function findFriend( headers:HeaderType, body:AllFriendBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/query",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 朋友圈详情
export function friendSuc( headers:HeaderType, body:FriendSucBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/queryById",
            method:"GET",
            headers,
            params:body,
        })
    )
}

// 点赞
export function friendGive( headers:HeaderType, body:GiveBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/like",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除评论
export function delFriendComment( headers:HeaderType, body:FriendSucBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/deleteComment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 点赞评论
export function friendCommentGive( headers:HeaderType, body:FriendSucCommentBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/commentLike",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 评论朋友圈
export function friendSucComment(headers: HeaderType, body: setCommentType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/comment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除朋友圈
export function delFriendSuc( headers:HeaderType, body:FriendSucBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/delete",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 朋友圈 评论列表
export function friendCommentList( headers:HeaderType, body:friendSucCommentListType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/commentList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 朋友圈 二级评论列表
export function friendSucCommentList( headers:HeaderType, body:friendSucCommentListType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/twoLevelCommentList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 发布朋友圈
export function issueFriend( headers:HeaderType, body:issueFriendType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/push",
            method:"POST",
            headers,
            data:body
        })
    )
}


// 我的朋友圈
export function findMyFriend( headers:HeaderType, body:AllFriendBody):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/queryMyMoment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的朋友圈 - 关于我的
export function myFriendAbout( headers:HeaderType, ):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/aboutMyMsg",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 我的朋友圈 - 关于我的 未读数量
export function myFriendAboutUnread( headers:HeaderType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/weiduCount",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 查询好友
export function findFriendSuc( headers:HeaderType,body:findFriendSucType):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/queryUser",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询好友朋友圈
export function findFriendCircle( headers:HeaderType,body:myFriendsCircleUpDataType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendMoment/queryFriendMoment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询好友朋友圈
export function IsJoinGroupChat( headers:HeaderType,body:IsJoinGroupChatType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendGroupMsg/isGroupChat",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 加入 群
export function JoinGroupChat( headers:HeaderType,body:IsJoinGroupChatType):Promise<any>{
    return (
        Axios({
            url:"/campus/friendGroupMsg/addGroup",
            method:"POST",
            headers,
            data:body
        })
    )
}
