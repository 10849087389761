
import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import MessageListStoreType from "@/views/Message/MessageList/Store/indexType";
import {BodyType} from "@/Api/Message/indexType";

@Module({
    name:"MessageList",
    store,
    dynamic: true,
})
export default class MessageList extends VuexModule implements MessageListStoreType{

    MessageListUpData: BodyType = {
        pageNo: 1, pageSize: 15
    };
    get getMessageListUpData(){ return this.MessageListUpData }
    @Mutation
    SetMessageListUpData(data: BodyType): void {
        this.MessageListUpData = data
    }

}

export const MessageListStore = getModule( MessageList )
