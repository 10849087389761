
declare const ap:any
declare const window:any;
declare const wx:any;

export function initLocation() {
    let phone = navigator.userAgent;
    let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1;
    let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    let zfbBool = phone.toLowerCase().match(/AlipayClient/i)

    if ( wxBool != null && wxBool[0] == "micromessenger" || window.__wxjs_environment == "miniprogram" ){
        let srcArr = [
            {
                url:"https://res.wx.qq.com/open/js/jweixin-1.6.0.js"
            },{
                url:"https://webapi.amap.com/maps?v=1.4.15&key=b1e68d6653f941493703afb64a7bb981",
                type:"text/javascript"
            },{
                url:"https://res.wx.qq.com/open/js/jweixin-1.3.2.js",
                type:"text/javascript"
            },
        ]
        srcArr.forEach((item,index)=>{
            let script=document.createElement("script")
            script.src = item.url
            document.body.appendChild(script)
        })
    }else{
        let srcArr = [
            {
                url:"https://webapi.amap.com/maps?v=1.4.15&key=b1e68d6653f941493703afb64a7bb981",
                type:"text/javascript"
            },
        ]

        srcArr.forEach((item,index)=>{
            let script = document.createElement("script")
            script.src = item.url
            script.type = item.type
            document.body.appendChild(script)
        })
    }

    // 判断是否为 小程序 引入操作
    // setTimeout(()=>{
    //     if (window.__wxjs_environment == "miniprogram") {
    //         let script=document.createElement("script")
    //         script.src = require("@/util/jweixin-1.3.2.js")
    //         script.type = "text/javascript"
    //         document.body.appendChild(script)
    //         setTimeout(()=>{
    //             alert(JSON.stringify(wx) + "---");
    //         },2000)
    //     }
    // })
}

declare const AMap:any

export function isMap() {
    return (
        new Promise((resolve, reject) => {
            let num = 0;
            let time = setInterval(()=>{
                if ( AMap && "Map" in AMap ){
                    clearInterval(time)
                    resolve(true)
                }else if ( num >= 1000 ){
                    clearInterval(time)
                    reject(false)
                }
                num ++;
            },300)
        })
    )
}


