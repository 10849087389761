

export default [
    {
        path:"/wallet",
        name:"wallet",
        component:()=>import("../../views/Money/Wallet/Wallet.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/billSuc",
        name:"billSuc",
        component:()=>import("../../views/Money/BillSuc/BillSuc.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addAccount",
        name:"addAccount",
        component:()=>import("../../views/Money/AddAccount/AddAccount.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/deposit",
        name:"deposit",
        component:()=>import("../../views/Money/Deposit/Deposit.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/depositRecord",
        name:"depositRecord",
        component:()=>import("../../views/Money/DepositRecord/DepositRecord.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/billDetail",
        name:"billDetail",
        component:()=>import("../../views/Money/BillDetail/BillDetail.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/goPay",
        name:"goPay",
        component:()=>import("../../views/Money/GoPay/GoPay.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/accountList",
        name:"accountList",
        component:()=>import("../../views/Money/AccountList/AccountList.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/billStatus",
        name:"billStatus",
        component:()=>import("../../views/Money/BillStatus/BillStatus.vue"),
        meta:{
            keepAlive: true
        }
    },{ // 等待审核
        path:"/awaitDeposit",
        name:"awaitDeposit",
        component:()=>import("../../views/Money/AwaitDeposit/AwaitDeposit.vue"),
        meta:{
            keepAlive: true
        }
    },
]
