export default [
    {
        path:"/enter",
        name:"enter",
        component:()=>import("@/views/School/Enter.vue")
    },
    {
        path:"/collega",
        name:"collega",
        component:()=>import("@/views/School/Collega.vue")
    },
    {
        path:"/major",
        name:"major",
        component:()=>import("@/views/School/Major.vue")
    },
    {
        path:"/class",
        name:"class",
        component:()=>import("@/views/School/Class.vue")
    },
    {
        path:"/semester",
        name:"semester",
        component:()=>import("@/views/School/Semester.vue")
    },
]