

// 功能开启状态
import Axios from "@/Api/Config/friend";
import {
    HeaderType,
    BodyType,
    SendChatType,
    GroupChatHistoryListType,
    GroupChatSendChatType,
    ChatListType,
    FriendsListType, FindFriendsType, AwaitApplyFriendType, ApplyFriendType
} from "@/Api/Message/indexType";

// 查询群聊天记录
export function GroupChatHistoryList (headers : HeaderType , body:GroupChatHistoryListType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friendGroupMsg/groupMsgList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 一对一聊天记录
export function ChatList (headers : HeaderType , body:ChatListType ):Promise<any>{
    return (
        Axios({
            url:"/app/chat/queryChat",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 发送群消息
export function GroupChatSendChat (headers : HeaderType , body:GroupChatSendChatType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friendGroupMsg/sendGroupChat",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 发送 一对一 消息
export function SendChat (headers : HeaderType , body:SendChatType ):Promise<any>{
    return (
        Axios({
            url:"/app/chat/sendSingleChar",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 消息列表
export function MessageList(headers : HeaderType , body:BodyType ):Promise<any>{
    return (
        Axios({
            url:"/app/chat/queryFriendList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 好友列表
export function FriendsList(headers : HeaderType , body:FriendsListType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/frientList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 好友申请列表
export function ApplyFriendsList(headers : HeaderType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/frientApplyList",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 请求好友申请列表的未读数量
export function ApplyFriendsUnreadNum(headers : HeaderType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/getApplyCount",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 推荐好友
export function RecommendFriends(headers : HeaderType , body:BodyType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/recommendFriend",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询好友
export function FindFriends(headers : HeaderType , body:FindFriendsType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/queryPhoneAdd",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 处理好友申请
export function AwaitApplyFriend(headers : HeaderType , body:AwaitApplyFriendType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/agreeFrientApply",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 申请好友
export function ApplyFriend(headers : HeaderType , body:ApplyFriendType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/applyAddFriend",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 申请好友
export function TheSchoolCampus(headers : HeaderType , body:BodyType ):Promise<any>{
    return (
        Axios({
            url:"/campus/friend/querySchoolAdd",
            method:"POST",
            headers,
            data:body
        })
    )
}
