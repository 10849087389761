
export default [
    {
        path:"/businessSuc",
        name:"businessSuc",
        component:()=>import("../../views/ShopAdmin/BusinessSuc/BusinessSuc.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/siteOrder",
        name:"siteOrder",
        component:()=>import("../../views/ShopAdmin/SiteOrder/SiteOrder.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/distance",
        name:"distance",
        component:()=>import("../../views/ShopAdmin/Distance/Distance.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addLimitTime",
        name:"addLimitTime",
        component:()=>import("../../views/ShopAdmin/AddLimitTime/AddLimitTime.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/selectCommodity",
        name:"selectCommodity",
        component:()=>import("../../views/ShopAdmin/SelectCommodity/SelectCommodity.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/limitTimeList",
        name:"limitTimeList",
        component:()=>import("../../views/ShopAdmin/LimitTimeList/LimitTimeList.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/limitTimeDate",
        name:"limitTimeDate",
        component:()=>import("../../views/ShopAdmin/LimitTimeDate/LimitTimeDate.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addLimitTimeDate",
        name:"addLimitTimeDate",
        component:()=>import("../../views/ShopAdmin/AddLimitTimeDate/AddLimitTimeDate.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/areasList", // storeId 店铺id state=shop店铺 bike 骑手
        name:"areasList",
        component:()=>import("../../views/ShopAdmin/AreasList/AreasList.vue"),
        meta:{
            keepAlive: true
        }
    },{
        path:"/addAreas",
        name:"addAreas",
        component:()=>import("../../views/ShopAdmin/AddAreas/AddAreas.vue"),
        meta:{
            keepAlive: true
        }
    },
]
