

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import MessageStoreType from "@/views/Message/Store/indexType";

@Module({
    name:"Message",
    store,
    dynamic: true,
})
export default class Message extends VuexModule implements MessageStoreType{

    // 驱动消息列表
    NewestMessageList: any[] = [];
    get getNewestMessageList(){ return this.NewestMessageList }
    @Mutation
    SetNewestMessageList(data: any[]): void {
        this.NewestMessageList = data
    }

    // 驱动消息列表数量
    NewestMessageNum = 0;
    get getNewestMessageNum(){ return this.NewestMessageNum }
    @Mutation
    SetNewestMessageNum(data: number): void {
        this.NewestMessageNum = data
    }

    // 最新聊天消息
    NewestMessageChat: any = {};
    get getNewestMessageChat(){ return this.NewestMessageChat }
    @Mutation
    SetNewestMessageChat(data: any): void {
        this.NewestMessageChat = data
    }

    // 最新群消息
    NewestMessageGroupChat: any = {};
    get getNewestMessageGroupChat(){ return this.NewestMessageGroupChat }
    @Mutation
    SetNewestMessageGroupChat(data: any): void {
        this.NewestMessageGroupChat = data
    }

    // 好友状态
    NewestMessageFriendStatus: any[] = [];
    get getNewestMessageFriendStatus(){ return this.NewestMessageFriendStatus }
    @Mutation
    SetNewestMessageFriendStatus(data: any[]): void {
        this.NewestMessageFriendStatus = data
    }

    // 好友未读状态数量
    NewestMessageFriendStatusNum = 0;
    get getNewestMessageFriendStatusNum(){ return this.NewestMessageFriendStatusNum }
    @Mutation
    SetNewestMessageFriendStatusNum(data: number): void {
        this.NewestMessageFriendStatusNum = data
    }

    // 底部导航栏未读数量 （包含）= 消息列表未读数量 + 好友未读状态数量
    NewestMessageNavNum = 0;
    get getNewestMessageNavNum(){ return this.NewestMessageNavNum }
    @Mutation
    SetNewestMessageNavNum(data: number): void {
        this.NewestMessageNavNum = data
    }

    // 输入框 获取焦点
    MessageInputFocus = false;
    get getMessageInputFocus(){ return this.MessageInputFocus }
    @Mutation
    SetMessageInputFocus(data: boolean): void {
        this.MessageInputFocus = data
    }

    // 打开选择 其他功能 比如上传图片
    MessageOtherShow = false;
    get getMessageOtherShow(){ return this.MessageOtherShow }
    @Mutation
    SetMessageOtherShow(data: boolean): void {
        this.MessageOtherShow = data
    }

    // Socket 对象
    MessageSocket: any = {};
    get getMessageSocket(){ return this.MessageSocket }
    @Mutation
    SetMessageSocket(data: any): void {
        this.MessageSocket = data
    }

}

export const MessageStore  = getModule( Message )
