export default [
    {
        path:"/allHelp",
        name:"allHelp",
        component:()=>import("../../views/Help/AllHelp.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/myHelp",
        name:"myHelp",
        component:()=>import("../../views/Help/MyHelp.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/helpSuc",
        name:"helpSuc",
        component:()=>import("../../views/Help/HelpSuc.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/allhelpSuc",
        name:"allhelpSuc",
        component:()=>import("../../views/Help/AllHelpSuc.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/takeHelp",
        name:"takeHelp",
        component:()=>import("../../views/Help/TakeHelp.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/issueHelp",
        name:"issueHelp",
        component:()=>import("../../views/Help/IssueHelp.vue"),
        meta:{
            keepAlive:true
        }
    },
]

