export default [
    {
        path:"/allShop",
        name:"allShop",
        component:()=>import("../../views/Shop/AllShop.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/shopSuc",
        name:"shopSuc",
        component:()=>import("../../views/Shop/ShopSuc.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/buyList",
        name:"buyList",
        component:()=>import("../../views/Shop/BuyList.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/orderList",
        name:"orderList",
        component:()=>import("../../views/Shop/OrderList.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/orderSuc",
        name:"orderSuc",
        component:()=>import("../../views/Shop/OrderSuc.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/commoditySuc",
        name:"commoditySuc",
        component:()=>import("../../views/Shop/CommoditySuc/CommoditySuc.vue"),
        meta:{
            keepAlive:true
        }
    },
    {
        path:"/feedbackShop",
        name:"feedbackShop",
        component:()=>import("../../views/Shop/FeedbackShop/FeedbackShop.vue"),
        meta:{
            keepAlive:true
        }
    }
]
