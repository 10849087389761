export default [
    {
        path:"/passLogin",
        name:"passLogin",
        component:()=>import("../../views/Login/PassLogin/PassLogin.vue"),
        meta:{
            keepAlive:true
        }
    },{
        path:"/register",
        name:"register",
        component:()=>import("../../views/Login/Register/Register.vue"),
        meta:{
            keepAlive:true
        }
    },{
	    path:"/keylogin",
	    name:"keylogin",
	    component:()=>import("../../views/Login/KeyLogin/KeyLogin.vue"),
	    meta:{
	        keepAlive:true
	    }
	},
]
