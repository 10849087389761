export function back() {
    try{
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isAndroid){
            window.android.extendHandleClick(1)
        }
        if(isiOS){
            window.webkit.messageHandlers.extendHandleClick.postMessage({"type": 1})
        }
    }catch (e) {
        console.info("返回失败")
    }
}
